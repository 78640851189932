<script>
import api from '@/services/api';
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import { BButton, BTable } from 'bootstrap-vue';

export default {
    name: 'AppCoinMerchant',
    components: {
        ProgressLinear,
        PageTitle,
        BButton,
        BTable
    },
    data() {
        return {
            items: [],
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            requestPending: false
        };
    },
    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        }
    },
    async mounted() {
        await this.getMerchants();
    },
    methods: {
        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        async getMerchants() {
            const body = {
                method: 'coin.merchant_get_all',
                params: {
                    page: this.pagination.current,
                    limit: this.pagination.perPage
                }
            };
            await api.coin
                .fetchCoinMerchants(body)
                .then((response) => {
                    this.items = response.data.result;
                    this.pagination = response.data.pagination;

                    if (this.pagination.current > this.pagination.totalItem) {
                        this.pagination.current = 1;
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title> {{ $t('avatars') }}</template>
            <template>
                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(id)="{ item }">
                    {{ item.id }}
                </template>
                <template #cell(name)="{ item }">
                    <div style="min-width: 200px">
                        {{ item.name }}
                    </div>
                </template>
                <template #cell(actions)="{ item }">
                    <div class="d-flex justify-content-end">
                        <b-button
                            v-b-tooltip.hover.top="`${$t('button.open')}`"
                            :to="{ name: 'avatar-show', params: { id: item.id } }"
                            class="btn-icon"
                            variant="flat-success"
                        >
                            <feather-icon icon="EyeIcon" />
                        </b-button>
                    </div>
                </template>
            </b-table>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}
</style>
